body, *{
  margin: 0;
  padding: 0;
}
body {
  background: #F4F3EE;
  position: relative;
}
li{
  list-style: none;
}

@font-face {
  font-family: 'halyard-medium';
  src: url(../fonts/halyard/Halyard-Micro-Medium.ttf);
}
@font-face {
  font-family: 'halyard-light';
  src: url(../fonts/halyard/Halyard-Micro-Light.ttf);
}
@font-face {
  font-family: 'halyard-regular';
  src: url(../fonts/halyard/Halyard-Text-Regular.ttf);
}
@font-face {
  font-family: 'instrument-medium';
  src: url(../fonts/instrument/InstrumentSans-Medium.ttf);
}
@font-face {
  font-family: 'instrument-regular';
  src: url(../fonts/instrument/InstrumentSans-Regular.ttf);
}

h1 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin: 0;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
h4 {
  font-family: 'instrument-medium';
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
}
h6 {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 0.65rem;
  letter-spacing: -0.02rem;
  color: #848382;
}
h6.f-20 {
  font-size: 0.9rem;
  color: #000;
  font-family: 'instrument-medium';
}
.container{
  max-width: 1920px;
  margin: auto;
}
.padding-x-all{
  padding: 0 6.8rem;
}
.sec-com-padding{
  padding: 6.5rem 0;
}
.relative{
  position: relative;
}
.flex-box{
  display: flex;
  flex-wrap: wrap;
}
.space-between{
  justify-content: space-between;
}
.align-center{
  align-items: center;
}
.selected {
  background: #f9f92f !important;
}



.main-menu-box {
  background: #fff;
  padding: 1.7rem 1rem;
  border-radius: 1.5rem;
  width: 14%;
  height: 100%;
}
.main-dashboard-box {
  padding: 2.2rem 1.6rem;
}
ul.menu-listing li .tab-box {
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  background: radial-gradient(50% 2% at 50% 99%, #aaa0a0 0%, rgba(80, 28, 28, 0) 100%);
  z-index: 10;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
ul.menu-listing li .tab-box:hover {
  background: #f4f3ee;
}
ul.menu-listing li .tab-box.selected, ul.menu-listing li .tab-box.pre-selected {
  background: #F9F92F !important;
}
ul.menu-listing li .tab-box.grey-bg{
  background: #F4F3EE;
}
ul.menu-listing li .tab-box.selected h6, ul.menu-listing li .tab-box.grey-bg h6, ul.menu-listing li .tab-box.pre-selected h6 {
  color: #000;
  font-weight: 600;
}
ul.menu-listing li .tab-box img {
  object-fit: contain;
  width: 0.7rem;
  height: 0.7rem;
  filter: opacity(0.5);
}
ul.menu-listing li .tab-box.selected img, ul.menu-listing li .tab-box.grey-bg img, ul.menu-listing li .tab-box.pre-selected img {
  filter: opacity(1);
}
ul.menu-listing li .tab-box h6 {
  display: flex;
  align-items: center;
}
ul.menu-listing li .tab-box h6 span {
  margin-right: 0.5rem;
}
h6.menu-sub-text {
  text-transform: uppercase;
  font-size: 0.55rem;
  margin-bottom: 0.8rem;
}
.tab-detail-outer-box {
  width: 80.2%;
}
h6.tab-detail-heading img {
  width: 0.7rem;
  height: 0.7rem;
  object-fit: contain;
  margin-right: 0.3rem;
}
h6.tab-detail-heading {
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}
ul.all-orders-listing li .order-box {
  background: #fff;
  padding: 1.5rem 1.2rem;
  border-radius: 0.5rem;
}
ul.all-orders-listing li .order-box:hover {
  background: #fbfbfb;
}
ul.all-orders-listing li .order-box h6 {
  margin-bottom: 0.3rem;
}
ul.all-orders-listing li .order-box {
  background: #fff;
  padding: 1.4rem 1.2rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
}
ul.all-orders-listing li .order-box .order-detail-box {
  border-right: 1px solid #D9D9D9;
  margin-right: 1.2rem;
  padding-right: 0.5rem;
}
ul.all-orders-listing li .order-box .order-detail-box:first-child {
  /* width: 15%; */
  width: 9.5%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  /* width: 9%; */
  width: 12%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  /* width: 16%; */
  width: 9%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  /* width: 11%; */
  width: 8.5%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  /* width: 13%; */
  width: 9%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(6) {
  /* width: 16%; */
  width: 7%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(7){
  border: none;
  width: 17%;
}
.order-cta-box img {
  width: 1.3rem;
  object-fit: contain;
}
.order-cta-box {
  width: 2.2rem;
  height: 2.2rem;
  background: #f9f92f;
  border-radius: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-inner-listing {
  position: relative;
  padding-left: 2rem;
  height: 0;
  overflow: hidden;
  margin: 0;
}
.tab-box.hide+div {
  height: 0;
  padding-top: 0;
}
.menu-inner-listing.full-height {
  height: 100%;
  padding-top: 0.5rem;
}
.menu-inner-tab-box {
  padding: 0.6rem 0.5rem;
  border-radius: 0.5rem;
  margin: 0.2rem 0;
  cursor: pointer;
}
.menu-inner-tab-box:hover{
  background-color: #F4F3EE;
}
.menu-inner-tab-box.selected h6 {
  color: #000;
  font-weight: 600;
}
.menu-inner-listing::after{
  content: '';
  position: absolute;
  background: url(../img/connection-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 9%;
  top: -1%;
  width: 1.5rem;
  height: 100%;
}
.user-detail-box {
  background: #fff;
  padding: 0.9rem 1.8rem;
  padding-bottom: 1.8rem;
  border-radius: 0.5rem;
}
.user-detail-box .head-box {
  margin-bottom: 0.7rem;
}
.user-detail-box h6.tab-detail-heading {
  font-weight: 100;
  font-size: 0.9rem;
}
ul.user-detail-listing li {
  width: 20%;
  border-left: 1px solid #B7B7B7;
  padding-left: 1.6rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 5.2rem;
}
ul.user-detail-listing{
padding-top: 0px !important;
  padding: 46px 0px;
  
}
.delivery-detail {
  border-top: 1px solid #B7B7B7;
  padding-top: 10px  !important;
  
}
.delivery-detail ul.user-detail-listing{
    padding-bottom: 0px !important;
    
  }
ul.user-detail-listing li:last-child {
  border-right: 1px solid #B7B7B7;
}
.delivery-detail ul.user-detail-listing li:nth-child(3) {
  width: 20%;
}

.delivery-detail .job-day-left-box h6 {
  margin-top: 0;
  margin-left: 0;
}
.delivery-detail .job-day-left-box select {
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.delivery-detail .job-day-left-box select+span {
  font-family: 'instrument-regular';
  font-size: 0.8rem;
  background: yellow;
  padding: 0.1rem 1rem;
  border-radius: 2rem;
}



ul.user-detail-listing li h6 {
  margin-bottom: 0.2rem;
}
h4#price-info {
  display: flex;
  align-items: center;
}
h4#price-info span img {
  object-fit: contain;
  width: 0.8rem;
  margin-left: 0.2rem;
  margin-bottom: -0.1rem;
  cursor: pointer;
}
.price-info-box {
  display: none;
  position: absolute;
  right: 3%;
  top: 118%;
  background: #fff;
  box-shadow: 0px 0px 22px 0px #0000000A;
  padding: 0.4rem 0.5rem;
  width: 6.5rem;
  border-radius: 0.3rem;
}
.price-info-box.show{
  display: block;
}
.price-info-box .text-box {
  display: flex;
  align-items: center;
}
.price-info-box h6 {
  font-size: 0.45rem;
  margin-bottom: 0;
}
.price-info-box .text-box h6:first-child {
  width: 70%;
}
.price-info-box .text-box h4 {
  margin: 0 0.3rem;
}
.price-info-box .text-box h6:last-child {
  width: 30%;
}
li.item-detail-box {
  margin: 1.2rem 0;
  background: #fff;
  border-radius: 0.5rem;
}
li.item-detail-box .head-box h6 img {
  margin-left: 1rem;
  width: 0.9rem;
  object-fit: contain;
  transition: 0.3s all;
}
li.item-detail-box .head-box+li {
  margin-top: -1rem;
}
li.item-detail-box .head-box h6 img.rotate{
  transform: rotateZ(180deg);
  transition: 0.3s all;
}
ul.item-detail-inner-box-listing {
  margin: 0.7rem -0.85rem;
  margin-bottom: 0;
  padding: 1.2rem 1.8rem;
  padding-top: 0;
  margin-top: -0.5rem;
  transition: 0.3s all;
}
li.item-detail-inner-box {
  width: 24%;
  border: 1px solid #D9D9D9;
  padding: 1rem 1.5rem;
  margin: 0.85rem;
  border-radius: 0.7rem;
}
li.item-detail-inner-box h6.tab-detail-heading {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  margin-top: 0.3rem;
}
li.item-detail-inner-box .field-box {
  margin-bottom: 0.8rem;
}
li.item-detail-inner-box .field-box:last-child {
  margin-bottom: 0;
}
li.item-detail-inner-box .field-box h6 {
  margin-bottom: 0.3rem;
}
li.item-detail-inner-box .field-box h4 {
  line-height: 1rem;
}
li.item-detail-inner-box .field-box h6.address {
  font-size: 0.7rem;
  margin-top: 0.3rem;
  width: 80%;
}
li.item-detail-inner-box .field-box video {
  width: 4rem;
  object-fit: contain;
}
li.item-detail-inner-box .field-box img {
  width: 3.5rem;
  object-fit: contain;
}
ul.items-listing li.item-detail-box ul.item-detail-inner-box-listing.show {
  display: none;
  transition: 0.3s all;
}
li.item-detail-box .head-box {
  cursor: pointer;
  padding: 1.2rem 1.8rem;
}
li.item-detail-inner-box .field-box .img-box img {
  object-fit: contain;
  width: 2.4rem;
  margin-right: .5rem;
  border-radius: 100%;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box {
  font-size: 0.3rem;
  color: #D9D9D9;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h4 {
  width: 30%;
  font-size: 0.6rem;
  text-align: end;
}
li.item-detail-inner-box .field-box.item-amount-box .text-box h6 {
  width: 35%;
  font-size: 0.5rem;
}
li.item-detail-inner-box .field-box.item-amount-box .text-head-box {
  margin-bottom: 0.2rem;
}

/* items css  */
.job-day-left-box select {
  border: none;
  background: transparent;
  border: 1px solid;
  border-radius: 0.4rem;
  font-family: 'instrument-regular';
  font-size: 0.6rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 2.2rem;
  margin-top: 1.2rem;
  cursor: pointer;
}
.job-day-left-box h6 {
  text-align: left;
  background: white;
  width: max-content;
  margin-top: 1.3rem;
  margin-bottom: -1.6rem;
  z-index: 10;
  position: relative;
  margin-left: 0.6rem;
  padding: 0 0.3rem;
  color: #000;
  font-weight: 700;
  font-size: 0.55rem;
  letter-spacing: 0;
}

.tab-detail-heading.payment-status .green{
  background-color: rgb(140, 252, 140);
padding: 0.1rem 0.5rem;
border-radius: 1rem;
}
.tab-detail-heading.payment-status .yellow{
 background-color: #f9f92f;
padding: 0.1rem 0.5rem;
border-radius: 1rem;
}

.tab-detail-heading.payment-status .red{
  background-color: #FFDCDC;
 padding: 0.1rem 0.5rem;
 border-radius: 1rem;
 }

.item-icon-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5rem;
}
.item-icon-box {
  width: 3rem;
  margin-top: -0.4rem;
}
.items-listing-box ul.all-orders-listing li .order-box{
  padding: 1.4rem 2.2rem;
}
.items-listing-box ul.all-orders-listing li .order-box .order-detail-box {
  margin-right: 3rem;
}
.items-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 12%;
  height: 2.1rem;
}
.items-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 33%;
}
.items-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 25%;
}
.items-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 23%;
}
h6.add-new-btn {
  display: flex;
  align-items: center;
  border: 1px solid #B4B4B4;
  border-radius: 2rem;
  padding: 0.3rem 0.8rem;
  color: #000;
  letter-spacing: 0;
  cursor: pointer;
  margin-bottom: 1rem;
}
h6.add-new-btn img {
  width: 0.5rem;
  margin-right: 0.3rem;
}

/* order email box */
.order-email-box {
  margin-top: 1.2rem;
}
.order-email-box .item{
  margin-top:0.7rem ;
  width: 25%;
  border: 1px solid #D9D9D9;
  padding: 0.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-email-sent{
background-color: #f4f3ee;

}
.order-email-box .item .email{

display: flex;
justify-content: space-between;
align-items: center;
}
.order-email-box .item .email img{
  object-fit: contain;
  width: 0.8rem;
  margin-left: 0.2rem;
  /* margin-bottom: -0.1rem; */
}
.order-email-box .container{
  display: flex;
  gap: 15px;
}

.order-email-box .yes-no-tab{
  margin-top: 10px;
justify-content: end;
}



/* pop up css  */

.popup-outer-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000b3;
}
.popup-box {
  background: #fff;
  padding: 1.2rem;
  border-radius: 0.6rem;
  position: relative;
}
button.popup-close-btn img {
  width: 0.8rem !important;
  height: 0.7rem;
  margin-bottom: -0.1rem;
  object-fit: contain;
}
button.popup-close-btn {
  position: absolute;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
  margin: 0;
  border: none;
  background: #E2E21A;
  width: 2rem;
  height: 2rem;
  border-radius: 5rem;
}
h4.popup-inner-heading {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
  font-family: 'instrument-regular';
  font-weight: 600;
}
.popup-inner-list-box {
  padding: 0.8rem 0;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
}
.popup-inner-list-box .field-box {
  margin-right: 1rem;
}
.popup-inner-list-box .field-box:last-child {
  margin-right: 0;
}
.popup-box h6 {
  margin-bottom: 0.3rem;
}
.popup-box .field-box.border-bottom {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 0.8rem;
}
.popup-payout-list-box {
  margin-top: 0.8rem;
  background: #F5F5F5;
  padding: 0.9rem 1rem;
  border-radius: 0.5rem;
}
.popup-payout-list-box h4.popup-inner-heading {
  border-bottom: 0;
}
a.popup-concern-btn {
  display: flex;
  color: #333333;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #333333;
  width: max-content;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
a.popup-concern-btn img {
  width: 0.7rem;
  margin-right: 0.15rem;
}
a.popup-concern-btn h6 {
  color: #333333;
  margin-bottom: 0.15rem;
  font-family: 'instrument-medium';
}
/* user edit css  */

.user-edit-popup {
  padding: 2.2rem 1.5rem;
}
.img-change-box {
  width: 30%;
}
.img-change-box img.user-edit-img {
  width: 6rem;
  height: 6rem;
  margin-right: 2.7rem;
  object-fit: cover;
  border-radius: 10rem;
}
.img-change-box img.user-change-img {
  position: absolute;
  width: 1.2rem;
  top: 5%;
  right: 5%;
  object-fit: contain;
  cursor: pointer;
}
.user-text-box {
  width: 57%;
}
.user-text-box .field-box {
  margin-bottom: 0.8rem;
}
.user-text-box .field-box h4 {
  font-size: 0.65rem;
}
.user-text-box .input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DBDBDB;
  margin-top: 0.5rem;
}
.user-text-box .input-box input {
  border: none;
  font-size: 0.65rem;
  font-family: 'instrument-regular';
  padding: 0.5rem 0.3rem;
  width: 71%;
  border-radius: 0.2rem;
}
.user-text-box .input-box button {
  background: transparent;
  border: none;
  font-family: 'instrument-medium';
  font-size: 0.62rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.address-edit-box input {
  font-family: 'instrument-regular';
  font-size: 0.65rem;
  width: 100%;
  padding: 0.5rem 0.3rem;
  border: 1px solid #DBDBDB;
  border-radius: 0.2rem;
}
button.save-info-btn {
  background: #E2E21A;
  border: none;
  padding: 0.5rem 0.2rem;
  margin-top: 1.5rem;
  border-radius: 2rem;
  font-size: 0.7rem;
  width: 5rem;
  font-family: 'instrument-medium';
  cursor: pointer;
}

/* item pop up css */

.item-popup .popup-down-box {
  border-top: 1px solid #D9D9D9;
  margin-top: 0.8rem;
  padding-top: 1rem;
}
.item-popup .popup-down-box h6 {
  margin-bottom: 0.5rem;
}
.img-change-box img.user-edit-img {
  width: 5rem;
  height: 5rem;
  margin-right: 4.7rem;
  object-fit: cover;
  border-radius: 10rem;
}
.item-popup .popup-down-box h4 {
  margin: 0.3rem 0;
}
.item-popup .popup-down-box .text-box h4:first-child {
  margin-right: 1.3rem;
}
.item-popup .popup-down-box .text-box h4.bold {
  font-family: 'instrument-regular';
  font-weight: 700;
}
.item-popup  .user-text-box .field-box h4 {
  font-size: 0.8rem;
}
.item-popup .user-text-box {
  width: 62%;
}
.edit-and-delete-btn img {
  width: 1.8rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.edit-and-delete-btn {
  position: absolute;
  right: 4%;
  top: 4%;
}
.item-popup.item-edit-popup button.popup-close-btn, .item-popup.item-del-popup button.popup-close-btn{
  display: block !important;
}
.item-popup.item-edit-popup img.user-change-img, .item-popup.item-del-popup img.user-change-img {
  display: block !important;
}
.item-popup.item-edit-popup .edit-and-delete-btn, .item-popup.item-del-popup .edit-and-delete-btn{
  display: none;
}
.popup-input {
  font-family: 'instrument-regular';
  font-size: 0.65rem;
  width: 100%;
  padding: 0.5rem 0.3rem;
  border: 1px solid #DBDBDB;
  border-radius: 0.4rem;
}
.item-popup .popup-input{
  width: 94%;
}
.item-popup.item-edit-popup .popup-input, .item-popup.item-del-popup .popup-input {
  display: block !important;
}
.item-popup.item-edit-popup h4.edit-name, .item-popup.item-del-popup h4.edit-name, .item-popup.item-edit-popup .edit-none, .item-popup.item-del-popup .edit-none {
  display: none;
}
.item-popup.item-del-popup .popup-input::placeholder {
  color: transparent;
}
.item-popup button.save-info-btn{
  margin-top: 1rem;
}
.bottom-save-box {
  margin-bottom: -0.8rem;
}
.item-popup.item-edit-popup .bottom-save-box, .item-popup.item-del-popup .bottom-save-box{
  display: flex !important;
}
.item-popup.item-del-popup .confirm-delete-box {
  display: flex !important;
  margin-top: 1.8rem;
  margin-bottom: -0.8rem;
}
.confirm-delete-box  button {
  background: #E2E21A;
  border: none;
  padding: 0.3rem 0.1rem;
  margin-left: 0.2rem;
  border-radius: 2rem;
  font-size: 0.55rem;
  width: 1.8rem;
  font-family: 'instrument-medium';
  cursor: pointer;
}
.add-from-services-btn h4 {
  font-size: 0.7rem;
}
.add-from-services-btn h4 span {
  margin-right: 1rem;
  font-family: math;
  font-size: 0.8rem;
}
.add-from-services-btn {
  border: 1px solid #DCDCDC;
  border-radius: 2rem;
  padding: 0 1.3rem;
  width: max-content;
  cursor: pointer;
}


/* serevice module css  */

.services-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 21%;
  padding-right: 0.5rem;
}
.services-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 10%;
}
.services-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 25%;
}
.services-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 21%;
}
.services-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 18%;
}
h6.green-box {
  background: #DCFFD7;
  border: 1px solid #36CB4E;
  width: max-content;
  padding: 0.2rem 0.7rem;
  border-radius: 1rem;
  margin: 0.4rem 0;
}
h4.service-title {
  font-size: 1.05rem;
}
.service-popup {
  max-width: 33rem;
}
.popup-item-list-box {
  padding-top: 1.3rem;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid #D9D9D9;
}
.popup-item-list-box .popup-item-list {
  margin: 0.8rem -0.7rem;
}
.popup-item-list-box .popup-item-list .popup-item {
  text-align: center;
  width: max-content;
  max-width: 4rem;
  margin: 0.2rem 0.8rem;
}
.popup-item-list-box .popup-item-list .popup-item .item-img-box {
  padding: 0.5rem;
  border-radius: 100%;
  border: 1px solid #D6D6D6;
  width: 1.1rem;
  height: 1.1rem;
  margin: auto;
  margin-bottom: 0.3rem;
}
.popup-item-list-box .popup-item-list .popup-item .item-img-box img.service-item-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup-type-box .type-box {
  margin-top: 0.8rem;
}
.popup-type-box .type-box .left-box h4, .popup-type-box .service-video-box .left-box h4 {
  margin-right: 4rem;
  width: 4.8rem;
}
.popup-type-box h6 {
  font-size: 0.7rem;
  margin-bottom: 0;
}
.popup-type-box h4.bold {
  font-family: 'instrument-regular';
  font-weight: 800;
}
.popup-type-box .type-box .left-box .mode-of-info img {
  width: 0.6rem;
  object-fit: contain;
  margin-right: 0.3rem;
  margin-left: 1rem;
}
.popup-type-box .type-box .left-box .mode-of-info {
  margin-left: -1.48rem;
}
img.delete-item-btn {
  position: absolute;
  width: 0.4rem;
  background: #fff;
  filter: invert(1);
  border-radius: 2rem;
  padding: 0.1rem;
  top: -5%;
  right: 0%;
  cursor: pointer;
}
.yes-no-checkbox label {
  font-family: 'instrument-regular';
  font-size: 0.7rem;
  display: inline-block;
  position: relative;
  padding-left: 0.9rem;
  cursor: pointer;
  user-select: none;
  margin-right: 1.5rem;
}
.yes-no-checkbox input[type="checkbox"] {
  display: none;
}
.yes-no-checkbox .checkbox-custom {
  position: absolute;
  top: 0.1rem;
  left: 0;
  height: 0.58rem;
  width: 0.55rem;
  background-color: #fff;
  border: 1px solid #A5A5A5;
  border-radius: 50%;
}
.yes-no-checkbox .checkbox-custom::after {
  content: "";
  display: block;
  border-radius: 50%;
  height: 0.35rem;
  width: 0.35rem;
  margin: 0.1rem;
  background-color: #fff;
}
.yes-no-checkbox .box-selected .checkbox-custom::after{
  background-color: #A5A5A5;
}
.service-edit-popup img.delete-item-btn, .service-edit-popup .bottom-save-box, .service-edit-popup .popup-input, .service-edit-popup .add-new-associated-box, .service-edit-popup .edit-service-video {
  display: block !important;
}
.service-edit-popup .left-box h4+h6, .service-edit-popup .left-box .mode-of-info, .service-edit-popup img.item-edit-btn, .service-edit-popup .popup-type-box h4.bold {
  display: none;
}
.service-edit-popup .yes-no-checkbox{
  display: flex !important;
}
.service-edit-popup .service-video-inner-box video{
  margin-left: 0;
}
.service-edit-popup button.save-info-btn {
  margin-top: -1rem;
}
.service-edit-popup .popup-input{
  width: 4rem;
}
.service-edit-popup .popup-type-box .type-box {
  margin-top: 0.5rem;
}
.service-edit-popup .popup-item-list-box {
  border: none;
  margin: 0.5rem 0;
}
.add-new-associated-box {
  position: absolute;
  right: 0%;
  top: 0%;
}
.add-new-associated-box input {
  font-family: 'instrument-regular';
  padding: 0.3rem;
  border: 1px solid #BFBFBF;
  border-radius: 0.3rem;
  width: 8rem;
}
.add-new-associated-box ul {
  background: #fff;
  box-shadow: 0px 14px 14px 0px #0000000D;
  font-family: 'instrument-regular';
  font-size: 0.63rem;
  border-radius: 0.4rem;
  overflow: hidden;
}
.add-new-associated-box ul li {
  margin: 0;
  cursor: pointer;
  padding: 0.2rem 0.8rem;
  z-index: 9;
  position: relative;
  background: #fff;
}
.add-new-associated-box ul li:hover {
  background: #dedede;
}
/* add service css  */
.add-service-popup input.popup-input.full-width {
  width: 97.5%;
  margin-bottom: 0.8rem;
}

/* customers css  */

.customers-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 17%;
}
.customers-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 21%;
}
.customers-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 12%;
}
.customers-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 16%;
}


.customer-detail-box ul.user-detail-listing li {
    width: max-content;
    max-width: 27%;
    border-right: 1px solid #E7E7E7;
    border-left: 0;
    padding-left: 0;
    padding-right: 4rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 7rem;
}
.customer-detail-box ul.user-detail-listing li:nth-child(3) {
  width: 26%;
}
.customer-detail-box ul.user-detail-listing li:nth-child(2) {
  width: 16%;
}
ul.user-detail-listing li:nth-child(2) {
  width: 27%;
}
ul.user-detail-listing li:nth-child(3) {
  width: 13%;
}
.delivery-detail ul.user-detail-listing {
  justify-content: start;
}
.delivery-detail ul.user-detail-listing li {
  height: 4.5rem;
}
.delivery-detail ul.user-detail-listing li:last-child {
 border-right: none;
}
.customer-detail-box.user-detail-box {
  background: #fff;
  padding: 1.8rem 1.8rem;
  padding-bottom: 2rem;
  border-radius: 0.5rem;
}
.customer-detail-box .total-amount-spend-box {
  background: #F9F92F;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
}
.customer-detail-box .total-amount-spend-box h4 {
  font-size: 1rem;
  margin-top: 0.9rem;
  font-weight: 600;
}
.customer-detail-box ul.user-detail-listing {
  width: 81%;
}

.status-dot {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -15%;
  right: -11%;
}
.orange {
  background: #FFC738;
  box-shadow: 0px 0px 6px 0px #FFC738;
}
.green {
  background: rgb(140, 252, 140);
   box-shadow: 0px 0px 6px 0px #1BD038;
}
.red{
  background: #FF3838;
  box-shadow: 0px 0px 6px 0px #FF3838;
}

.recent-order-detail-box .recent-order-box {
  background: #fff;
  border-radius: .5rem;
  margin-bottom: .7rem;
  padding: 1.4rem 1.7rem;
  text-decoration: none;
}
ul.recent-order-listing {
  margin-top: 1.2rem;
}
ul.recent-order-listing .field-box {
  border-right: 1px solid #d9d9d9;
  margin-right: 1.5rem;
}

ul.recent-order-listing li .field-box:first-child {
  width: 14%;
}
ul.recent-order-listing li .field-box:nth-child(2) {
  width: 19%;
}
ul.recent-order-listing li .field-box:nth-child(3) {
  width: 20%;
}
ul.recent-order-listing li .field-box:nth-child(4) {
  width: 12%;
}
ul.recent-order-listing li .field-box:nth-child(5) {
  width: 10%;
  border-right: 0;
}
ul.recent-order-listing li .field-box:nth-child(5) h4{
  width: max-content;
}
.load-more-btn {
  margin: auto;
  margin-top: 1rem;
  width: max-content;
  background: #F9F92F;
  padding: 0.7rem 2.5rem;
  font-weight: 600;
  font-family: 'instrument-medium';
  z-index: 5;
  position: relative;
  cursor: pointer;
  border-radius: 0.4rem;
}
.load-more-btn:hover{
  background: #f4f41a;
}
.load-more-outer-box {
  margin-top: -6rem;
  background: linear-gradient(180deg, rgba(244, 243, 238, 0) -8.38%, #F4F3EE 59.44%);
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  z-index: 3;
  position: relative;
}
h6.contact-develop-text a {
  color: #00499F;
  text-underline-offset: 0.2rem;
}
h6.contact-develop-text {
  text-transform: uppercase;
  color: #333333;
}
.search-field {
  font-family: 'instrument-regular';
  padding: 0.5rem;
  padding-left: 2rem;
  border: none;
  border-radius: 0.3rem;
  width: 9rem;
}
h4.export-csv-btn span img {
  width: 0.6rem;
  object-fit: contain;
  margin-left: 1.5rem;
}
h4.export-csv-btn {
  margin-left: 2rem;
  cursor: pointer;
}
.customers-listing-box .tab-detail-head-box {
  margin-bottom: 1.3rem;
}
.search-icon{
  position: relative;
}
.search-icon::after{
  content: '';
  position: absolute;
  background: url(../img/search-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  left: 2%;
  top: 32%;
  width: 0.8rem;
  height: 0.8rem;
}

/* fixers css  */

.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box {
  margin-right: 1rem;
}
.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 13%;
}
.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 19%;
}
.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 26%;
}
.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 13%;
}
.fixes-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: max-content;
  border-right: 0;
}
.fixes-listing-box .popup-down-box{
  width: 20rem;
}
.fixes-listing-box .popup-down-box .field-box.w-50 {
  width: 40%;
  margin-right: 0.5rem;
  margin-bottom: 0.6rem;
}
h6.resent-pass-btn {
  margin-bottom: 0;
  color: #000;
  padding: 0.2rem 1rem;
  border: 1px solid #D8D8D8;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.6rem;
}
.fixes-listing-box .popup-down-box .field-box.w-50 .popup-input {
  width: 80%;
}
.fixes-listing-box .popup-down-box .field-box.w-50 .popup-input.w-100 {
  width: 10.7rem;
}
.fixes-listing-box .popup-down-box .field-box.w-100 {
  width: 100%;
}
.fixes-listing-box .popup-down-box .field-box input {
  width: 19.2rem;
}
.fixer-popup.item-edit-popup .edit-and-delete-btn, .fixer-popup.item-del-popup .edit-and-delete-btn {
  display: flex;
  top: 2%;
}
.fixer-popup.item-edit-popup .edit-and-delete-btn img.item-edit-btn{
  display: none;
}
.fixer-popup.item-del-popup .edit-and-delete-btn img.item-del-btn, .fixer-popup.item-del-popup .edit-and-delete-btn img.item-edit-btn{
  display: none !important;
}
.fixer-popup .img-change-box img.user-edit-img{
  width: 5.5rem;
  height: 5.5rem;
}
.specialty-box input:last-child {
  margin-right: 0;
}
.specialty-box input {
  width: 3.7rem !important;
  margin-right: 0.8rem;
}
.field-box.mt6 {
  margin-top: 0.6rem;
}
img.copy-icon {
  position: absolute;
  width: 0.8rem;
  right: 5%;
  object-fit: contain;
  top: 29%;
  cursor: pointer;
}
.specialty-box button {
  border: 1px solid #DBDBDB;
  background: transparent;
  padding: 0 0.5rem;
  font-family: 'instrument-regular';
  border-radius: 0.3rem;
  cursor: pointer;
}
.specialty-box button strong {
  font-weight: 100;
  font-size: 0.9rem;
  margin-right: 0.1rem;
  font-family: math;
}

/* discount css  */

.discounts-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 14%;
}
.discounts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 14%;
}
.discounts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 17%;
}
.discounts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 10%;
}
.discounts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 20%;
  border-right: 0;
}
.discount-popup .field-box.w-50 {
  width: 50%;
  margin-bottom: 1.4rem;
}
.discount-popup{
  width: 33rem;
  padding: 2.2rem;
}
.add-discount-popup .user-text-box {
  width: 100%;
}
.add-discount-popup .field-box.w-50 {
  width: 49%;
}
.select-type-box {
  margin-bottom: 0.8rem;
}
.select-category-box {
  margin-top: 0.8rem;
}
.add-discount-popup {
  width: 25rem;
}
.react-datepicker-wrapper {
  width: 100%;
}
input.date-picker {
  font-family: 'instrument-regular';
  color: #000;
  border: 1px solid #DBDBDB;
  padding: 0.5rem 0.3rem;
  font-size: 0.65rem;
  border-radius: 0.4rem;
  width: 93%;
  position: relative;
  z-index: 3;
  background: transparent;
  cursor: pointer;
}
.date-box-icon::after{
  content: '';
  position: absolute;
  background: url(../img/date-picker-icon.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 5%;
  top: 50%;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  z-index: 1;
}
input.popup-input.width-full {
  width: 97%;
}
.select-usage-box {
  margin-top: 0.8rem;
}

/* fixer payouts css  */

.payouts-listing-box select option {
  background: #fff;
  color: #333;
  font-family: 'instrument-medium';
  font-size: .8rem;
}
.payouts-listing-box select {
  background: #0000;
  border: none;
  color: #333;
  cursor: pointer;
  font-family: 'instrument-medium';
  font-size: .8rem;
  margin: 0 1rem;
  border-left: 1px solid #A8A8A8;
  padding-left: 1rem;
}
.payouts-listing-box img.sort-icon {
  width: 0.6rem;
  object-fit: contain;
  padding-left: 1rem;
  border-left: 1px solid #A8A8A8;
  cursor: pointer;
}
h6.tab-detail-heading strong {
  background: #ff0;
  border-radius: 2rem;
  font-size: .5rem;
  margin-left: .4rem;
  padding: .1rem .7rem;
}

.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 14%;
}
.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 32%;
}
.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 16%;
}
.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 16%;
}
.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 13%;
}
.payouts-listing-box ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) h4{
  position: relative;
  width: max-content;
}
.tailor-payout-box {
  width: 5.5rem;
  background: #F9F92F;
  text-align: center;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
}
.tailor-payout-box h3 {
  font-family: 'instrument-medium';
  font-size: 1.2rem;
  font-weight: 700;
  color: #333333;
}
.tailor-payout-box select {
  border: none;
  background: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
  margin: 0;
  font-size: 0.6rem;
}
.tailor-payout-box option {
  font-size: 0.6rem;
}  
.fixer-payouts-popup .user-text-box {
  width: 40%;
}
.fixer-payouts-popup .img-change-box {
  width: 22%;
}
.tailor-payout-box h4 {
  font-size: 0.5rem;
  text-align: start;
  margin-left: 0.8rem;
  margin-bottom: -0.3rem;
  margin-top: 1rem;
  z-index: 2;
  position: relative;
  background: #F9F92F;
  width: max-content;
  padding: 0 0.2rem;
}
.fixer-payouts-popup .user-text-box h4.mb {
  margin-bottom: 0.4rem;
}
.fixer-payouts-listing {
  border: 1px solid #E0E0E0;
  margin-top: 1rem;
  border-radius: 1rem;
}
.fixer-payouts-listing  .title-box {
  padding: 0 0.6rem;
  padding-top: 0.7rem;
}
.fixer-payouts-listing  .list-box {
  padding: 0.5rem 0.6rem;
  border-top: 1px solid #D9D9D9;
}
.fixer-payouts-listing .field-box h4 {
  font-size: 0.65rem;
}
.fixer-payouts-listing .field-box h6 {
  font-size: 0.55rem;
  margin-bottom: 0.6rem;
}
.fixer-payouts-listing .field-box:first-child {
  width: 15%;
}
.fixer-payouts-listing .field-box:nth-child(2) {
  width: 14%;
}
.fixer-payouts-listing .field-box:nth-child(3) {
  width: 15%;
}
.fixer-payouts-listing .field-box:nth-child(4) {
  width: 44%;
}
.fixer-payouts-listing .field-box:nth-child(5) {
  width: 11%;
}

/* zipcode css  */

h4.export-csv-btn.add-zip-code {
  border-left: 1px solid #A8A8A8;
  padding-left: 2rem;
  cursor: pointer;
}
.zipcode-listing-box ul.all-orders-listing {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 1.5rem 1.7rem;
  padding-right: 1rem;
  border-radius: 1rem;
  margin-top: 0.3rem;
}
h6.active-tag {
  width: 100%;
  font-weight: 700;
  margin-bottom: 0.7rem;
}
.zipcode-listing-box ul.all-orders-listing li {
  width: max-content;
}
.zipcode-listing-box ul.all-orders-listing li .order-box {
  background: #fff0;
  padding: 0.2rem 0.8rem;
  border-radius: 2rem;
  margin-bottom: 0.8rem;
  margin-right: 0.5rem;
  border: 0.7px solid #5D5D5D;
}
.zipcode-listing-box ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 4.2rem;
  border-right: 0;
  align-items: center;
  margin: 0;
}
.zipcode-listing-box ul.all-orders-listing li .order-box .order-detail-box img {
  width: 0.6rem;
  object-fit: contain;
}
.zipcode-listing-box ul.all-orders-listing li .order-box:hover {
  background: #f9f92f;
}
.zip-code-popup .field-box {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #D9D9D9;
}
.zip-code-popup .text-box {
  width: 13rem;
  margin-right: 2rem;
}
.zip-code-popup .text-box .field-box.no-border {
  border-bottom: 0;
  padding-bottom: 0;
}
button.bottom-del-btn {
  width: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-del-btn img {
  width: 0.6rem;
  margin-left: 1rem;
}
.zip-code-popup.confirm-box .text-box, .zip-code-popup.confirm-box .map-box {
  display: none;
}
.zip-code-popup.confirm-box .confirm-delete-box {
  display: block !important;
  text-align: center;
}
.zip-code-popup.confirm-box button.save-info-btn.confirm-del-btn {
  width: 5rem;
}
.zip-code-popup.confirm-box button.save-info-btn.cancel-btn {
  width: 4rem;
  background: transparent;
  border: 1px solid #D0D0D0;
  color: #7F7F7F;
}
.zip-code-popup.confirm-box .confirm-delete-box h4 {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}
.can-not-del-box.warning {
  display: block !important;
}
.can-not-del-box.warning h4 {
  width: 85%;
  margin: auto;
  margin-top: 1rem;
  font-size: 0.85rem !important;
}
.zip-code-popup.confirm-box {
  width: 17rem;
}
.zip-code-popup.confirm-box .popup-edit-box {
  justify-content: center;
}
.add-zip-code .text-box {
  width: 15rem;
  margin-right: 2rem;
}
.add-zip-code .text-box .flex-box .input-field-box {
  width: 46%;
  margin-bottom: 0.3rem;
}
.input-field-box .popup-input {
  width: 100%;
  margin-bottom: 0.6rem;
}

li.item-detail-box .head-box h6 span.unassigned {
  font-size: 0.5rem;
  text-transform: uppercase;
  margin-left: 0.7rem;
  border: 1px solid;
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
}
li.item-detail-box .head-box h6 span.unassigned.clr-red {
  color: #FF2929;
  background: #FFDCDC;
}
li.item-detail-box .head-box h6 span.unassigned.clr-green {
  color: #7AE836;
  background: #DFFFDC;
}
.assign-popup {
  width: 35rem;
  height: 22rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.assign-tailor-box {
  width: 44%;
  border: 1px solid #E3E3E3;
  border-radius: 0.5rem;
  margin-bottom: 1.1rem;
  padding: 0.8rem 0.8rem;
}
.assign-tailor-box .img-box {
  width: 20%;
  margin-right: 0.8rem;
}
.assign-tailor-box .img-box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.assign-tailor-box .name-box {
  width: 73%;
}
.assign-tailor-box .tag-box {
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
}
.assign-tailor-box .tag-box h6 {
  margin: 0;
  margin-right: 0.3rem;
  padding: 0.2rem 0.4rem;
  width: 3rem;
  text-align: center;
  border-radius: 1rem;
  color: #000;
  font-size: 0.6rem;
  background-color: #EDEDED;
}
.assign-tailor-box:hover {
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 0px 28.3px 0px #0000001F;
  border-color: transparent;
}
.assign-box input.popup-input {
  cursor: pointer;
  padding-left: 0.8rem;
  width: 94%;
}
button.assign-btn.save-info-btn {
  margin: 0;
  font-size: 0.6rem;
  padding: 0.3rem 0.2rem;
  width: 3rem;
  position: absolute;
  right: 0%;
  top: 46%;
}
.service-video-inner-box video {
  width: 1.8rem;
  margin-left: 1rem;
  margin-top: 0.4rem;
  cursor: pointer;
}
.add-service-popup .service-video-inner-box video{
  width: 5rem;
  border: 1px solid #cccccc;
  border-radius: 0.2rem;
}
/* subscriber css  */

ul.all-subscribers-listing li .order-box .order-detail-box:first-child {
  width: 17%;
}
ul.all-subscribers-listing li .order-box .order-detail-box:nth-child(2) {
  width: 12%;
}
ul.all-subscribers-listing li .order-box .order-detail-box:nth-child(3) {
  width: 30%;
}
ul.all-subscribers-listing li .order-box .order-detail-box:nth-child(4) {
  width: 19%;
  border-right: none;
}

/* login css  */

.login-main-box h2 {
  font-family: 'halyard-light';
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 2.1rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}
.login-main-box p {
  font-family: 'instrument-regular';
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.1rem;
  letter-spacing: 0rem;
}
.login-right-box .form-box .feild-box {
  margin-top: 1rem;
  flex-flow: column;
}
.form-box .feild-box input {
  padding: 0.5rem 0.3rem;
  padding-left: 0.8rem;
  font-family: 'instrument-regular';
  border: 1px solid #DBDBDB;
  border-radius: 0.4rem;
}

/* new edit for login  */

.login-left-box {
  background: url(../img/log-in-left-bg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: end;
  height: 75vh;
  border-radius: 1rem;
  padding: 2rem 2.3rem;
  width: 38%;
  display: flex;
  flex-flow: column;
}

.login-main-box {
  padding: 2rem;
  height: max-content;
  position: relative;
}
.login-main-box::after{
  content: '';
  position: absolute;
  background: url(../img/login-after.webp);
  background-repeat: no-repeat;
  background-size: contain;
  right: 4%;
  top: 6%;
  width: 6rem;
  height: 3rem;
}
.login-left-box .text-box {
  background: linear-gradient(100.08deg, rgb(88 88 88 / 77%) -4.89%, rgb(99 99 99) 105.72%);
  box-shadow: 0px 26.36px 39.93px 0px #0000001A;
  padding: 2rem 1.8rem;
  padding-top: 1rem;
  border-radius: 1rem;
}
.login-main-box h2{
  font-size: 1.8rem;
}
.login-main-box p {
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: #fff;
}
.login-main-box h2{
  color: #ffffff;
}
.login-right-box {
  width: 44%;
}
.login-right-box h1 {
  font-size: 1.6rem;
  -webkit-text-stroke-width: 0.01rem;
  letter-spacing: -0.1rem;
  line-height: 3rem;
}
.login-right-box h6 {
  font-size: 1.05rem;
}
.login-right-box .form-box .feild-box{
  align-items: start;
}
.form-box .feild-box input{
  width: 75%;
  margin-top: 0.4rem;
  height: 1.8rem;
  background-color: transparent;
  font-size: 0.85rem;
}
.form-box .feild-box h4 {
  margin-top: 1rem;
  color: #5A5A5A;
}
.form-box button.save-info-btn {
  height: 3rem;
  border-radius: 0.5rem;
  width: 7.5rem;
  font-weight: 700;
  font-size: 0.86rem;
  font-family: 'halyard-medium';
}

.login-main-box  img.clear-icon {
  position: absolute;
  right: 24%;
  top: 41%;
  width: 1.1rem;
  cursor: pointer;
}
.login-main-box  .relative.cross-box{
  width: 100%;
}



/* after css  */

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem; /* Equivalent to h-32 */
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Equivalent to space-x-2 */
}

.loader-dot {
  width: 1rem; /* Equivalent to w-4 */
  height: 1rem; /* Equivalent to h-4 */
  background-color: #3b82f6; /* Equivalent to bg-blue-500 */
  border-radius: 50%; /* Equivalent to rounded-full */
  animation: bounce 0.5s infinite alternate; /* Equivalent to animate-bounce */
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1rem); /* Adjust as needed */
  }
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.upload-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #45a049;
}

.tab-detail-heading.payment-status{
  display: flex;
  justify-content: space-between;
  
  }
  
  .tab-detail-heading.payment-status .green{
    background-color: rgb(140, 252, 140);
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  }
  .tab-detail-heading.payment-status .yellow{
   background-color: #f9f92f;
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  }
  
  .tab-detail-heading.payment-status .red{
    background-color: #FFDCDC;
   padding: 0.1rem 0.5rem;
   border-radius: 1rem;
   }


/* orders overview css  */

.right-btn-box h6 {
    background: #D6D1C4;
    padding: 0.2rem 0.7rem;
    margin: 0 0.2rem;
    border-radius: 3rem;
    color: #000;
    font-size: 0.48rem;
    font-weight: 600;
    cursor: pointer;
}

.right-btn-box h6.active {
  background: #E2E21A;
}
ul.all-overview-listing li {
  width: 12.4%;
  border-radius: 0.6rem;
  padding: 0.5rem;
  height: max-content;
}
ul.all-overview-listing li:first-child{
  background: #FEF0BF;
}
ul.all-overview-listing li:nth-child(2){
  background: #C9EFE8;
}
ul.all-overview-listing li:nth-child(3){
  background: #FEE2C1;
}
ul.all-overview-listing li:nth-child(4){
  background: #FFD3F0;
}
ul.all-overview-listing li:nth-child(5){
  background: #D0F9DC;
}
ul.all-overview-listing li:nth-child(6){
  background: #FFD8D6;
}
ul.all-overview-listing li:nth-child(7) {
  background: #d6e0ff;
}
.overview-detail {
  background: #FFFFFF;
  margin: 0.45rem 0;
  border-radius: 0.5rem;
  padding: 0.4rem;
}
.overview-name h4 {
  font-size: 1rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
}
.overview-detail h6.order-id {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.3rem;
  margin-bottom: 0.5rem;
}
.overview-detail h6.order-id strong {
  color: #000;
  font-weight: 400;
  margin-left: 0.15rem;
}
.overview-detail h5{
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  line-height: 0.8;
  font-weight: bold;
}

.customerorderdetailname{
  font-size: 0.7rem;
  font-weight: bold;
  color: black;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
li.item-detail-inner-box .field-box {
  font-family: 'instrument-regular';
  font-size: 0.8rem;
}



@media all and (min-width: 1920px) {
  html {
    font-size: 26px;
  }
}   
@media all and (max-width: 1920px) {
  html {
    font-size: 1.4vw;
  }
}
@media all and (min-width: 767px) {
   .desktop-none{
     display: none;
   }

   /* desktop css fix */

  .delivery-detail ul.user-detail-listing li {
    min-height: 2.5rem;
    height: max-content;
  }
  ul.user-detail-listing li {
    width: 28% !important;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  ul.user-detail-listing li:last-child {
    border-right: 0;
    margin-top: 0 !important;
  }
  ul.user-detail-listing li .text-box+.text-box {
      margin-top: 1rem;
  }

  .customer-detail-box ul.user-detail-listing li{
    width: max-content !important;
  }
  .customer-detail-box ul.user-detail-listing li:last-child{
    border-right: 1px solid #E7E7E7;
  }
  .service-popup{
    width: 30rem;
  }
  .overview-detail h6.order-id strong{
    font-size: 0.5rem;
  }

}  
@media all and (max-width: 767px) {
  html {
    font-size: 4.5vw;
  }
  .mobile-none{
    display: none;
  }
  h6{
    font-size: 0.8rem;
  }
  /* h4{
    font-size: 0.9rem;
  } */
  .main-menu-box {
    height: max-content;
}
.main-menu-box .menu-listing {
  position: fixed;
  width: 0%;
  height: 100%;
  left: -111%;
  background: #fff;
  top: 0;
  z-index: 50;
  transition: all 0.5s;
  padding: 1rem;
}
.main-menu-box .menu-listing.active {
  left: 0;
  transition: all 0.5s;
  width: 91.5%;
  /* width: -webkit-fill-available; */
}
  .main-dashboard-box {
      padding: 0;
  }
  .tab-detail-outer-box {
    width: -webkit-fill-available;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
h6.tab-detail-heading + div {
  width: 50%;
  margin: 0 !important;
  align-items: center;
  height: 2.8rem;
}
h6.tab-detail-heading {
  width: 50%;
  font-size: 0.8rem;
  height: 2.8rem;
  margin: 0;
}
h4.export-csv-btn span img{
  margin-left: 0.3rem;
}
ul.all-orders-listing li .order-box {
  padding: 1.2rem 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.8rem;
}
ul.all-orders-listing li .order-box .order-detail-box:first-child {
  width: 30%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(2) {
  width: 62%;
  margin-right: 0;
  border: none;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(3) {
  width: 30%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(4) {
  width: 28%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(5) {
  width: 28%;
  margin-right: 0;
  border: none;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(6) {
  width: 30%;
}
ul.all-orders-listing li .order-box .order-detail-box:nth-child(7) {
  border: none;
  width: 63%;
  margin-right: 0;
}
.order-cta-box {
  display: none;
}
ul.all-orders-listing li .order-box .order-detail-box {
  margin-right: 0.5rem;
  margin-bottom: 0.8rem;
  padding-right: 0.3rem;
}


.user-detail-box h6.tab-detail-heading + h6 + h6 {
  text-align: end;
  width: 100%;
  margin-top: -0.3rem;
}
ul.user-detail-listing {
  padding: 0;
}
ul.user-detail-listing li {
  width: max-content !important;
  height: max-content !important;
  padding: 0;
  border: none;
  margin-right: 0.5rem;
}
.delivery-detail ul.user-detail-listing {
  justify-content: space-between;
}
ul.user-detail-listing li .text-box {
  margin-bottom: 0.5rem;
}
.delivery-detail ul.user-detail-listing li:last-child {
  width: 100% !important;
}
.delivery-detail .job-day-left-box h6 {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  color: #848382;
}
.user-detail-box {
  width: -webkit-fill-available;
  padding: 1rem;
}
ul.user-detail-listing li:last-child{
  border: none;
}
.menu-inner-listing::after{
  left: 5%;
}
.user-detail-box+ul.items-listing {
  width: 100%;
}
.delivery-detail ul.user-detail-listing .field-box {
  margin-bottom: 0.7rem;
}
.order-email-box .item {
  margin-top: 0rem;
  padding: 0.4rem 0.5rem;
  width: max-content;
}
.order-email-box .container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.6rem;
}
.order-email-box .item .email img {
  margin-left: 1rem;
}
ul.all-orders-listing li .order-box h6 {
  margin-bottom: 0.2rem;
}
ul.user-detail-listing li h4 {
  font-size: 0.9rem;
}
ul.user-detail-listing li h6 {
  font-size: 0.9rem;
}
.user-detail-box h6.tab-detail-heading {
  font-size: 1rem;
  height: 2rem;
}
ul.user-detail-listing h6 span {
  font-size: 0.7rem;
}
.price-info-box {
  width: 14rem;
  left: 25%;
}
.order-email-box {
  margin-top: 0.2rem;
}
.order-email-box .text-box h6 {
  text-transform: uppercase;
}
.order-email-box .text-box .container h6 {
  text-transform: inherit;
  font-size: 0.7rem;
}
li.item-detail-box .head-box {
  padding: 0.8rem;
}
li.item-detail-box .head-box h6:first-child {
  width: 90%;
}
li.item-detail-box {
  margin: 0.8rem 0;
}
li.item-detail-inner-box {
  width: 100%;
  padding: 0.8rem;
  margin: 0;
  margin-bottom: 0.8rem;
}
li.item-detail-inner-box h6.tab-detail-heading{
  width: 100%;
  height: max-content;
}
li.item-detail-inner-box .field-box:last-child {
  width: 100%;
  margin-bottom: 0.5rem;
}
li.item-detail-box .head-box h6 span.unassigned {
  margin-left: 0;
}
li.item-detail-box li .head-box {
  padding-top: 0;
}
li.item-detail-box li.item-detail-box {
  margin-top: 0;
}
li.item-detail-box .head-box h6 h4 {
  margin-bottom: 0.2rem;
} 
li.item-detail-box li .head-box {
  margin-bottom: 0.5rem;
}
li.item-detail-box .head-box h6 h4 {
  margin-bottom: 0.2rem;
}
li.item-detail-box hr {
  display: none;
}

.menu-inner-listing{
  padding-left: 3.1rem;
}

.main-menu-box .menu-listing li {
  display: none;
}
.main-menu-box .menu-listing li.order-main-tab, .main-menu-box .menu-listing li.orders-overview-tab {
  display: block;
}
img.close-mobile-menu {
  width: 1.4rem;
  object-fit: contain;
  margin-bottom: 1rem;
}
h6.menu-sub-text {
  font-size: 0.7rem;
}
ul.menu-listing li .tab-box h6 {
  font-size: 0.85rem;
}
ul.menu-listing li .tab-box {
  padding: 1rem;
}
.menu-inner-tab-box{
  padding: 0.8rem 0.7rem;
}
.mobile-menu img {
  width: 1.4em;
  object-fit: contain;
}
.main-menu-box {
  height: max-content;
  padding: 0.5rem 0.5rem;
  padding-bottom: 0.3rem;
  border-radius: 0.3rem;
  margin: 0.6rem;
  width: max-content;
  margin-bottom: 0;
}
ul.all-overview-listing li {
  width: 100%;
  padding: 0.7rem;
}
.tab-detail-outer-box.items-listing-box h6.tab-detail-heading, .tab-detail-outer-box.items-listing-box h6.tab-detail-heading + div {
  width: max-content;
}
.tab-detail-outer-box.items-listing-box .head-box {
  width: 100%;
  border-top: 1px solid #0000003d;
  border-bottom: 1px solid #0000003d;
  margin: 1rem 0;
}
.right-btn-box h6{
  font-size: 0.5rem;
}
.overview-name h4 {
  font-size: 1.2rem;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}
.overview-detail {
  padding: 1rem 0.8rem;
  margin: 0.6rem 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.overview-detail h6.order-id {
  padding-bottom: 0.6rem;
  margin-bottom: 0.7rem;
  width: 100%;
}
.overview-detail h4 {
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
  width: max-content;
}
.overview-detail h6.name {
  width: 100%;
}
.price-and-no {
  width: 5.2rem;
}
.orders-overview-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: -0.2rem;
}
.overview-tab {
  border: 1px solid #0000004a;
  border-radius: 3rem;
  padding: 0.15rem 0.55rem;
  margin: 0.17rem 0.2rem;
}
.overview-tab h6 {
  font-size: 0.7rem;
}

.overview-tab.active h6 {
  color: #000;
}
.overview-tab.active {
  background: #E2E21A;
  border-color: #000;
}

.tab-detail-outer-box .all-overview-listing .overview-inner-list {
  display: none;
}

.tab-detail-outer-box .all-overview-listing .overview-inner-list.visible {
  display: block;
}

/* login page mobile css  */

.login-main-box {
  padding: 0.8rem;
}
.login-left-box {
  width: 100%;
  height: 21rem;
  padding: 0.8rem;
}
.login-left-box .text-box {
  padding: 0.8rem;
}
.login-main-box h2 {
  margin-bottom: 0.2rem;
}
.login-right-box {
  width: 100%;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}
.login-main-box .form-box .feild-box input {
  width: 94%;
}
.login-main-box .form-box button.save-info-btn {
  height: 2.6rem;
}
.login-main-box::after{
  display: none;
}
ul.all-orders-listing {
  margin-top: 1rem;
}
.search-field {
  margin-top: -1rem;
}



}












/* src/CustomDropdown.css */
.custom-dropdown {
  position: relative;
  width: 200px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Remove default dropdown arrow */
  background-color: #f9f9f9;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsPSIjNzc3IiBkPSJNNS4yOTMgNi4yOTNsMi43MDctMi43MDZMMTYgOC45OTNsLTIuNzA3IDIuNzA3TDggOGwtMi43MDcgMi43MDdMMCA4Ljk5M2wyLjcwNy0yLjcwNnpNNi43MDcgMTMuNzA3TDguOTkzIDExIDEwLjI5MyAxMi4yOTNsMi43MDYtMi43MDZMMTAgNkw3LjI5MyA4LjcwN0wwIDEuNzkzeiIvPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.custom-select option {
  background-color: #fff;
  color: #333;
}

.custom-select:focus {
  border-color: #007bff;
  outline: none;
}



/* Loader CSS Circle One */
/* Overlay to cover the whole screen */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top */
}

/* Circle Loader */
.loader-circle {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-top: 8px solid #3498db; /* Blue spinning color */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* CSS Loader Custom */


@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.square {
  background: #ddd;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

#sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}