body {
  font-family: "halyard-light";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-family: "halyard-light";
  font-weight: 600;
  margin: 0;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.2rem;
}

h4 {
  font-family: "instrument-medium";
  font-size: 0.8rem;
  font-weight: 100;
  color: #333333;
}

h6 {
  font-family: "instrument-regular";
  font-weight: 100;
  font-size: 0.65rem;
  letter-spacing: -0.02rem;
  color: #848382;
}

h6.f-20 {
  font-size: 0.9rem;
  color: #000;
  font-family: "instrument-medium";
}

.container {
  max-width: 1920px;
  margin: auto;
}

.invoice-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  background: #fff;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice-header .logo h1 {
  margin: 0;
  color: #000;
  font-size: 24px;
}

.invoice-header .invoice-number p {
  margin: 0;
  text-align: right;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.details-block {
  width: 30%;
}

.details-block p {
  margin: 4px 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background: #f0f0f0;
}

.invoice-summary {
  text-align: right;
}

.invoice-summary p {
  margin: 4px 0;
}
